import cls from "classnames";
import Link from "next/link";

import { button, link } from "@qoohoo/common/constants/analytics";
import Spinner from "@qoohoo/common/library/loader/Spinner";
import useHasHydrated from "@qoohoo/common/hooks/useHasHydrated";
import { mergeDeep } from "@qoohoo/common/utils/object";
import NavLayout from "@qoohoo/common/components/NavBar/NavLayout";
import LinksDropDown from "@qoohoo/common/components/LinksDropDown";

import { creatorOnboardingUrl } from "constants/constants";
import { dashboardDomain } from "constants/domain";
import useSessionStore from "store/sessionStore";
import useStore from "store/store";
import HamMenuMain from "components/HamMenu/HamMenuMain";
import { ArrowIcon } from "components/KYCIcons";
import { LoginButton } from "components/auth/AuthButtons";

const links = [
  {
    key: link.telegram_payments_product_page,
    label: "Telegram Payments",
    link: `/telegram-payments`,
    trackType: "link",
  },
  {
    key: link.whatsapp_payments_product_page,
    label: "WhatsApp Payments",
    link: `/whatsapp-payments`,
    trackType: "link",
  },
  {
    key: link.monetise_youtube_videos_product_page,
    label: "Monetise YouTube",
    link: `/monetise-youtube-videos`,
    trackType: "link",
  },
];

export const allNavItems = [
  {
    key: link.about,
    label: "About",
    link: "/about",
    trackType: "link",
  },
  {
    key: link.features,
    label: "Features",
    dropdown: links,
    trackType: "link",
  },
  {
    key: button.become_creator,
    label: "Become a creator",
    link: creatorOnboardingUrl,
    trackType: "button",
  }
];

export function AuthComponent({ theme, className, currentScreen, ...otherProps }) {
  const userData = useSessionStore((state) => state.userData);
  const isLoading = useStore((state) => state.loading);
  const isHydrated = useHasHydrated();

  const isLoggedIn = !!userData?.uid;

  const openDashboard = () => {
    location.href = dashboardDomain;
  };

  const buttonStyle = {
    background: theme.background,
    color: theme.primaryColor,
    border: `1px solid ${theme.borderColor}`,
  };

  let content;

  if (isLoading || !isHydrated) {
    content = <Spinner className={className} size="xs" {...otherProps} />;
  } else if (isLoggedIn) {
    content = (
      <button
        className={cls("ml-2 h-10 px-3 group rounded-full overflow-hidden flex items-center justify-center cursor-pointer hover:brightness-90", theme.buttonClass)}
        onClick={openDashboard}
        style={buttonStyle}
        {...otherProps}
      >
        <div className="flex items-center gap-2">
          <div>Dashboard</div>
          <div className="hidden md:block translate-x-0 group-hover:translate-x-1 transition-transform">
            <ArrowIcon className="block" color={theme.primaryColor} />
          </div>
        </div>
      </button>
    );
  } else {
    content = (
      <LoginButton
        isCreatorLogin={true}
        className={cls("ml-2 h-10 px-3 group rounded-full overflow-hidden flex items-center justify-center cursor-pointer hover:brightness-90", theme.buttonClass)}
        style={buttonStyle}
        {...otherProps}
      >
        <div className="flex items-center gap-2">
          <div>Login</div>
          <div className="hidden md:block translate-x-0 group-hover:translate-x-1 transition-transform">
            <ArrowIcon className="block" color={theme.primaryColor} />
          </div>
        </div>
      </LoginButton>
    );
  }

  return content;
}

AuthComponent.theme = {
  dark: {
    background: "rgba(255,255,255,0.07)",
    primaryColor: "#ffffff",
    borderColor: "transparent",
    buttonClass: "",
  },
  light: {
    background: "rgba(0,0,0,0.07)",
    primaryColor: "#16191B",
    borderColor: "transparent",
    buttonClass: "",
  }
}

export default function MainNavbar({
  navItems = allNavItems,
  currentScreen,
  isSticky = false,
  showAuth = false,
  theme = null,
  isDark = true,
}) {

  const defaultTheme = isDark ? MainNavbar.theme.dark : MainNavbar.theme.light;
  const navTheme = mergeDeep(defaultTheme, theme);
  
  const hamTheme = navTheme.ham;
  const authTheme = navTheme.auth;

  const QoohooLogo = () => (
    <Link href={`/`}>
      <img
        src={navTheme.logo}
        alt="qoohoo logo"
        className="h-11 object-contain select-none"
      />
    </Link>
  );

  return (
    <NavLayout
      currentScreen={currentScreen}
      navItems={navItems}
      isSticky={isSticky}
      theme={navTheme}
      logoComponent={(props) => <QoohooLogo {...props} />}
      authNavComponent={(props) =>
        showAuth ? (
          <AuthComponent className="ml-4" theme={authTheme} {...props} />
        ) : null
      }
      hamMenuComponent={(props) => (
        <HamMenuMain theme={hamTheme} {...props} />
      )}
    />
  );
}

MainNavbar.theme = {
  dark: {
    primaryColor: "#ffffff",
    logo: "/assets/images/qoohoo-logo-white.svg",
    auth: AuthComponent.theme.dark,
    ham: HamMenuMain.theme.dark,
    dropdown: LinksDropDown.theme.dark,
    linkHoverClass: "btn-hover-bg",
  },
  light: {
    primaryColor: "#16191b",
    logo: "/assets/images/qoohoo-logo-dark.svg",
    auth: AuthComponent.theme.light,
    ham: HamMenuMain.theme.light,
    dropdown: LinksDropDown.theme.light,
    linkHoverClass: "btn-hover-bg btn-hover-bg-light",
  },
};